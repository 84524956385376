import { useEffect, useState } from "react";
import LegoLoader from './animations/LegoLoader.json';
import panda_parasute from './animations/panda_parasute.json'
import Animation from '../../ui/Animation'
import Button from "../../ui/Button";
import style from './style.module.scss'


// loader timeout
const timeout = 2000;

// onelink generate
const generateLink = () => {
    const oneLinkURL = 'https://mio.onelink.me/OP8A';
    const mediaSource = {keys:['utm_source'],defaultValue:'Media%20Source'};
    const campaign = {keys:['utm_campaign'],defaultValue:'Campaign'};
    const channel = {keys:['utm_medium'],defaultValue:'Channel'};
    const adSet = {keys:['utm_adset'],defaultValue:'Adset%20name'};
    const ad = {keys:['utm_content'],defaultValue:'Ad%20name'};
    const afSub1 = {keys:['fbclid','ttclid','gclid'],defaultValue:'clid'};
    const custom_ss_ui = {paramKey:'af_ss_ui',defaultValue:'true'};

    const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
        oneLinkURL: oneLinkURL,
        afParameters: {
            mediaSource: mediaSource,
            campaign: campaign,
            channel: channel,
            adSet: adSet,
            ad: ad,
            afSub1: afSub1,
            afCustom: [
                custom_ss_ui
            ]
        }
    });

    return result;
}


const Success = ({ age, count, callback }) => {
    const [loading, setLoading] = useState(true)
    const [link, setLink] = useState('')

    const send = () => {
        window.fbq('track', 'Lead')

        window.ttq.track('Submit Form')

        callback({step: 4})
        // window.location.href = `${link.clickURL}&deep_link_value=ageid_${age}-answer_${count}`
    }

    useEffect(() => {
        setTimeout(() => setLoading(false), timeout)
    }, [count]);

    useEffect(() => {
        if(!loading){
            window.fbq('track', 'ViewContent', {
                content_type: 'Onboarding',
                content_id: 3,
                content_category: 'page',
                contents: 'Complete Screen'
            })

            window.ttq.track('View Content', {
                content_type: 'Onboarding',
                content_id: 3,
                content_category: 'page',
                contents: 'Complete Screen'
            })

            setLink(generateLink())
        }
    }, [loading])


    return loading ? (
        <div className={style.loaderContainer}>
            <div>
                <Animation data={LegoLoader} />
            </div>

            <div className={style.loaderText}>Loading...</div>
        </div>
    ) : (
        <div className='section'>
            <div className={style.animation}>
                <Animation
                    data={panda_parasute}
                />
            </div>

            <div className={style.info}>
                <div className={style.text}>Congratulations! Your study plan is ready</div>

                <div className={style.note}>Enjoy the app and keep in mind that even 5 minutes of practice a day can help your child speak better</div>

                <div className={style.actions}>
                    <Button callback={send}>
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Success