import dataJson from '../../../data/questions.json'
import panda_1 from './animations/panda_1.json'
import panda_2 from './animations/panda_2.json'
import panda_3 from './animations/panda_3.json'
import panda_4 from './animations/panda_4.json'
import panda_5 from './animations/panda_5.json'

import { useEffect, useState } from 'react'
import Animation from '../../ui/Animation'
import Button from '../../ui/Button';

import style from './style.module.scss'


const animations = {
    panda_1,
    panda_2,
    panda_3,
    panda_4,
    panda_5
}

const Age = ({ callback = () => null }) => {
    const [age, setAge] = useState(null)
    const [btnActive, setBtnActive] = useState(false)

    const questions = dataJson.questions

    const next = () => {
        let data = questions[questions.length - 1].identifier

        if(age != null) data = age

        callback({
            step: 2,
            age: data
        })
    }

    useEffect(() => {
        setAge(null)

        window.fbq('track', 'ViewContent', {
            content_type: 'Onboarding',
            content_id: 1,
            content_category: 'page',
            contents: 'Age Screen'
        })

        window.ttq.track('View Content', {
            content_type: 'Onboarding',
            content_id: 1,
            content_category: 'page',
            contents: 'Age Screen'
        })
    }, [])

    useEffect(() => {
        if(age){
            setBtnActive(true)
        }
    }, [age]);

    return (
        <div className={`section ${style.section}`}>
            <div className={`title ${style.title}`}>Enter the app user's date of birth</div>

            <div className={style.list}>
                {questions.map((question, key) => (
                    <div className={`${style.item} ${question.identifier === age ? style.active : ''}`} key={key}>
                        <svg width='136' height='96' viewBox='0 0 136 96' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path className={style.border} d='M5.28336 10.22C10.1725 -4.94281 118.611 -0.772809 127.8 6.95341C136.989 14.6796 141.477 80.5322 124.741 87.853C108.005 95.1738 27.6355 96.7376 11.4011 85.8722C-4.8334 75.0069 -0.683901 28.4989 5.28336 10.22Z' fill='#9DB1E2'/>
                            <path className={style.bg} d='M5.28336 12.5368C10.1725 -2.62609 118.611 1.5439 127.8 9.27012C136.989 16.9963 141.477 82.8489 124.741 90.1697C108.005 97.4905 27.6355 99.0543 11.4011 88.1889C-4.8334 77.3236 -0.683901 30.8156 5.28336 12.5368Z' fill='#ABEAFF'/>
                        </svg>

                        <div className={style.info} onClick={() => setAge(question.identifier)}>
                            <div className={style.image}>
                                <Animation data={animations[question.category_image]} width={60} height={48} />
                            </div>

                            <div className={style.name}>{question.category_name}</div>
                        </div>
                    </div>
                ))}
            </div>

            <div className={style.actions}>
                <Button
                    type={[!btnActive ? 'disabled' : '']}
                    callback={next}
                >
                    Continue
                </Button>

                <div className={`skip ${style.skip}`}
                    onClick={next}
                >skip</div>
            </div>
        </div>
    )
}

export default Age
  