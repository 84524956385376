import dataJson from '../../../data/questions.json'
import { useEffect, useState, useMemo } from 'react'
import Button from '../../ui/Button';
import style from './style.module.scss'

const Question = ({ data }) => {
    return (
        <div className={style.question}>
            <div className={`${style.title} title`}>{data.question}</div>

            <div className={style.note}>{data.subquestion}</div>
        </div>
    )
}


const Questions = ({ age, callback }) => {
    const [current, setCurrent] = useState(0)
    const [currentQuestion, setCurrentQuestion] = useState()
    const [answers, setAnswers] = useState([])

    const data = useMemo(() => dataJson.questions.filter(question => question.identifier === age)[0]?.question || [], [age]);

    const handler = answer => {
        setAnswers([...answers, answer])

        setCurrent(current + 1);
    }

    const back = () => {
        setAnswers([...answers.slice(0, answers.length - 1)])

        setCurrent(current - 1);
    }

    useEffect(() => {
        if(current < 0){
            callback({
                step: 1
            })
        }else if(current > (data.length - 1)){
            callback({
                step: 3,
                count: answers.filter(item => item === 'yes').length
            })
        }

        setCurrentQuestion(data[current])
    }, [current, answers, callback, data])


    return (
        <div className={`section ${style.section}`}>
            <div className={style.backBlock}>
                <div className={style.back} onClick={back} />
            </div>

            {currentQuestion && <Question data={currentQuestion} />}

            <div className={style.actions}>
                <Button
                    type={['small', style.button]}
                    callback={() => handler('yes')}
                >
                    yes
                </Button>

                <Button
                    type={['small', 'cancel', style.button]}
                    callback={() => handler('no')}
                >
                    no
                </Button>

                <div className={`skip ${style.skip}`} onClick={() => handler('no')}>skip</div>
            </div>

            <div className={style.dots}>
                {data.map((item, key) => <div className={`${style.dot}${key === current ? ` ${style.active}` : ''}`} key={key} />)}
            </div>
        </div>
    )
}

export default Questions
  