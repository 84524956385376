import Lottie from 'react-lottie';

const Animation = ({ data, width = '', height = '' }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: data
    };

    return(
        <div>
            <Lottie options={defaultOptions}
                width={width}
                height={height}
            />
        </div>
    )
}

export default Animation