// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/back.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../assets/images/back_active.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_section__RiyWW {
  padding: 20px;
  max-height: 100%;
}

.style_backBlock__LRuhe {
  width: 100%;
  margin: 20px 0 8px;
}

.style_back__mPgty {
  width: 64px;
  height: 64px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
}
.style_back__mPgty:active {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.style_question__B2D4N {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  width: calc(100% + 10px);
  padding-right: 10px;
  margin: 0 0 24px;
}

.style_title__luG5O {
  color: #fff;
  margin: 0 0 16px;
}

.style_note__pYQ7C {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #9DB1E2;
}

.style_actions__JVnXq {
  max-height: 208px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.style_button__uAzeh {
  margin: 0 0 16px;
}

.style_skip__Y\\+pfa {
  margin: auto 0 0;
}

.style_dots__drzXZ {
  display: flex;
  justify-content: center;
  margin: 20px 0 0;
}

.style_dot__UEDcX {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background: #89E2FF;
  border-radius: 50%;
}
.style_dot__UEDcX.style_active__jUMuS {
  background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/screens/questions/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gBAAA;AACJ;;AAEA;EACI,WAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,6DAAA;AACJ;AACI;EACI,yDAAA;AACR;;AAGA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;EACA,wBAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,WAAA;EAEA,gBAAA;AADJ;;AAIA;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;AADJ;;AAIA;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,gBAAA;AADJ;;AAIA;EACI,gBAAA;AADJ;;AAIA;EACI,aAAA;EACA,uBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAGI;EACI,gBAAA;AADR","sourcesContent":[".section {\n    padding: 20px;\n    max-height: 100%;\n}\n\n.backBlock {\n    width: 100%;\n    margin: 20px 0 8px;\n}\n\n.back {\n    width: 64px;\n    height: 64px;\n    background: url(../../../assets/images/back.svg) no-repeat;\n\n    &:active {\n        background-image: url(../../../assets/images/back_active.svg);\n    }\n}\n\n.question {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    overflow: auto;\n    width: calc(100% + 10px);\n    padding-right: 10px;\n    margin: 0 0 24px;\n}\n\n.title {\n    color: #fff;\n\n    margin: 0 0 16px;\n}\n\n.note {\n    font-size: 16px;\n    line-height: 20px;\n    text-align: center;\n    color: #9DB1E2;\n}\n\n.actions {\n    max-height: 208px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.button {\n    margin: 0 0 16px;\n}\n\n.skip {\n    margin: auto 0 0;\n}\n\n.dots {\n    display: flex;\n    justify-content: center;\n    margin: 20px 0 0;\n}\n\n.dot {\n    width: 10px;\n    height: 10px;\n    margin: 0 5px;\n    background: #89E2FF;\n    border-radius: 50%;\n\n    &.active {\n        background: #fff;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `style_section__RiyWW`,
	"backBlock": `style_backBlock__LRuhe`,
	"back": `style_back__mPgty`,
	"question": `style_question__B2D4N`,
	"title": `style_title__luG5O`,
	"note": `style_note__pYQ7C`,
	"actions": `style_actions__JVnXq`,
	"button": `style_button__uAzeh`,
	"skip": `style_skip__Y+pfa`,
	"dots": `style_dots__drzXZ`,
	"dot": `style_dot__UEDcX`,
	"active": `style_active__jUMuS`
};
export default ___CSS_LOADER_EXPORT___;
