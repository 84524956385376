import { useState, useEffect } from "react";
import Home from './screens/home/Home'
import Age from "./screens/age/Age";
import Questions from './screens/questions/Questions'
import Success from "./screens/success/Success";
import Buy from "./screens/buy/Buy";


const Main = () => {
    const [step, setStep] = useState(0)
    const [screen, setScreen] = useState('')
    const [age, setAge] = useState(null)
    const [yesCount, setYesCount] = useState(0)

    const set = ( data ) => {
        if(data?.age) setAge(data.age)

        if(data?.count) setYesCount(data.count)

        setStep(data.step)
    }

    useEffect(() => {
        switch(step){
            case 0:
                setScreen(<Home callback={set} />)
                break
            case 1:
                setScreen(<Age callback={set} />);
                break;
            case 2:
                setScreen(<Questions age={age} callback={set} />);
                break;
            case 3:
                setScreen(<Success age={age} count={yesCount} callback={set} />);
                break;
            case 4:
                setScreen(<Buy />);
                break;
            default:
                break
        }
    }, [step, age, yesCount]);

    return (
        <div className='container'>
            {screen}
        </div>
    )
}

export default Main