import panda_swimming from './animations/panda_swimming.json'
import { useState } from 'react'
import Animation from '../../ui/Animation'
import Button from '../../ui/Button';
import style from './style.module.scss'


const options = [
    {
        name: '1 month',
        per: '119,88$/year',
        price: '9,99 USD',
        info: [
            'Cancel anytime',
            'No ads',
            'New exercises every month'
        ],
        btnText: 'start'
    },
    {
        name: '1 year',
        old: '125$/year',
        per: '49,99$/year',
        price: '4,17 USD',
        info: [
            'Cancel anytime',
            'No ads',
            'New exercises every month',
            '7 days - Free'
        ],
        btnText: 'Start a free week'
    }
]


const Buy = ({ callback = () => null }) => {
    const [selected, setSelected] = useState(0)

    return (
        <div className={`section ${style.section}`}>
            <div className={style.image}>
                <Animation
                    data={panda_swimming}
                    width={202}
                />
            </div>

            <div className={`title ${style.title}`}>Get unlimited access to effective learning now!</div>

            <div className={style.form}>
                <div className={style.options}>
                    {options.map((option, index) => 
                        <div className={`${style.option} ${(index === selected) ? style.selected : ''}`} key={index} onClick={() => setSelected(index)}>
                            {!!option.old ? <span className={style.label}>-60%</span> : ''}
                            <div className={style.name}>
                                {option.name}
                            </div>
                            <div className={style.per}>
                                {!!option.old ? <span>{option.old}</span> : ''}
                                {option.per}
                            </div>
                            <div className={style.price}>
                                {option.price}
                            </div>
                        </div>
                    )}
                </div>

                <div className={style.info}>
                    <div className={style.list}>
                        {options[selected].info.map(item =>
                            <div className={style.item}>{item}</div>
                        )}
                    </div>

                    <Button
                        type={['blue', style.btn]}
                        className={''}
                    >
                        {options[selected].btnText}
                    </Button>
                </div>
            </div>

            <div className={style.actions}>
                <div className={`skip ${style.skip}`}>Restore purchases</div>

                <div className={style.note}>
                    Automatic subscription renewal. Cancel at any time
                </div>
            </div>
        </div>
    )
}

export default Buy
  