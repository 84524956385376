import panda_main from './animations/panda_main.json';
import Animation from '../../ui/Animation'
import Button from '../../ui/Button';
import style from './style.module.scss'
import { useEffect } from 'react';

const Home = ({ callback }) => {
    useEffect(() => {
        window.fbq('track', 'ViewContent', {
            content_type: 'Onboarding',
            content_id: 0,
            content_category: 'page',
            contents: 'Welcome Screen'
        })

        window.ttq.track('View Content', {
            content_type: 'Onboarding',
            content_id: 0,
            content_category: 'page',
            contents: 'Welcome Screen'
        })
    }, [])

    return (
        <div className={`section ${style.section}`}>
            <div className={style.tooltip}>
                <svg width="279" height="162" viewBox="0 0 279 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M218.764 162C218.764 162 245.005 143.945 228.915 135.539C212.825 127.133 48.5 144.5 21.3959 119.977C-5.70816 95.4537 -4.96047 43.6335 15.9989 24.8457C36.9583 6.05788 71.7375 0.418958 119.393 0.418945C151.341 0.418937 234.5 0.418972 256.601 24.8442C278.703 49.2695 281.5 66.4999 276.07 108.752C270.639 151.004 218.764 162 218.764 162Z" fill="white"/>
                </svg>
                <div className={style.text}>Welcome to the Mio app!</div>
            </div>

            <div className={style.image}>
                <Animation
                    data={panda_main}
                    width={554}
                    height={440 / 320 * 425}
                />
            </div>

            <div className={style.actions}>
                <Button
                    type={[style.button]}
                    callback={() => callback({step: 1})}
                >
                    Continue
                </Button>
            </div>
        </div>
    )
}

export default Home
  