// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/home_clouds.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_section__4kEhZ {
  --main_container_width: 440px;
  --main_container_bg: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat 50% 50%;
}

.style_image__Yzwas {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: -120px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.style_tooltip__8GnAf {
  position: relative;
  top: -16px;
  margin: auto;
}

.style_text__RrC3l {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 54px 20px;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.08;
  text-align: center;
  color: var(--yellow);
}

.style_actions__z\\+1Ti {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 94px;
  margin: 12px 0 20px;
}
.style_actions__z\\+1Ti .style_button__oAzaW {
  height: 62px;
  width: 185px;
}`, "",{"version":3,"sources":["webpack://./src/components/screens/home/style.module.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,8EAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,OAAA;EACA,QAAA;EACA,cAAA;EAEA,aAAA;EACA,qBAAA;EACA,uBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,UAAA;EACA,YAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,4BAAA;EAEA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,oBAAA;AAHJ;;AAMA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EAEA,gBAAA;EACA,mBAAA;AAJJ;AAMI;EACI,YAAA;EACA,YAAA;AAJR","sourcesContent":[".section {\n    --main_container_width: 440px;\n    --main_container_bg: url(../../../assets/images/home_clouds.svg) no-repeat 50% 50%;\n}\n\n.image {\n    position: absolute;\n    z-index: -1;\n    left: 0;\n    right: 0;\n    bottom: -120px;\n\n    display: flex;\n    align-items: flex-end;\n    justify-content: center;\n}\n\n.tooltip {\n    position: relative;\n    top: -16px;\n    margin: auto;\n}\n\n.text {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    padding: 20px 20px 54px 20px;\n\n    font-weight: bold;\n    font-size: 28px;\n    line-height: 1.08;\n    text-align: center;\n    color: var(--yellow);\n}\n\n.actions {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    min-height: 94px;\n    margin: 12px 0 20px;\n\n    .button {\n        height: 62px;\n        width: 185px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `style_section__4kEhZ`,
	"image": `style_image__Yzwas`,
	"tooltip": `style_tooltip__8GnAf`,
	"text": `style_text__RrC3l`,
	"actions": `style_actions__z+1Ti`,
	"button": `style_button__oAzaW`
};
export default ___CSS_LOADER_EXPORT___;
